import { GetStaticProps, InferGetStaticPropsType } from 'next';
import {
  getHotNews,
  getRecommendSkills,
  getSuperHotOpeningJobs,
  getTotalNumberOpeningJobs
} from 'services/graphql/directus';
import { JobType, NewsType } from 'types';
import { AppEnv } from 'utils/accessEnviroment';
import { getFulfilledValue } from 'utils/promise';

import {
  DEFAULT_MENUS_FOOTER,
  DEFAULT_RECRUITMENT_MENUS_HEADER,
  Menu,
  NormalizeMenu,
  getRecruitmentMenusHeaderAndFooter,
  normalizeMenus
} from '@dnse/services';

import { Benefit, Events, Hero, News, OpeningJobs } from '../components/Home';
import { DNSEHead } from '../components/common/DNSEHead';
import { CONST } from '../constants';
import RecruitmentLayout from '../layout';

type PageProps = {
  menusHeader: Menu[];
  menusFooter: NormalizeMenu;
  jobs: JobType[];
  news: NewsType[];
  recommendSkills: string[];
  totalNumberOpeningJobs: number;
};

const Recruitment = ({
  menusHeader,
  menusFooter,
  jobs,
  news,
  recommendSkills,
  totalNumberOpeningJobs
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <RecruitmentLayout menusFooter={menusFooter} menusHeader={menusHeader}>
      <DNSEHead
        description={
          'DNSE luôn tìm kiếm, tuyển dụng và tưởng thưởng xứng đáng với những bạn trẻ có đam mê, nhiệt huyết với công việc khi gia nhập cùng chúng tôi.'
        }
      />
      <Hero
        recommendSkills={recommendSkills}
        totalNumberOpeningJobs={totalNumberOpeningJobs}
      />
      <Benefit />
      <OpeningJobs openingJobList={jobs} />
      <Events events={CONST.Events} />
      <News newsList={news} />
    </RecruitmentLayout>
  );
};
export default Recruitment;

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const [
    getTotalNumberOpeningJobsRes,
    getOpeningJobsRes,
    getHotNewsRes,
    getMenusRes,
    getRecommendSkillsRes
  ] = await Promise.allSettled([
    getTotalNumberOpeningJobs(),
    getSuperHotOpeningJobs(3),
    getHotNews(3),
    getRecruitmentMenusHeaderAndFooter(),
    getRecommendSkills()
  ]);
  const jobs = getFulfilledValue(getOpeningJobsRes, []);
  const news = getFulfilledValue(getHotNewsRes, []);
  const menus = getFulfilledValue(getMenusRes, {
    menusHeader: DEFAULT_RECRUITMENT_MENUS_HEADER,
    menusFooter: normalizeMenus(DEFAULT_MENUS_FOOTER)
  });
  const recommendSkills = getFulfilledValue(getRecommendSkillsRes, []);
  const totalNumberOpeningJobs = getFulfilledValue(
    getTotalNumberOpeningJobsRes,
    0
  );
  return {
    props: {
      jobs,
      news,
      menusHeader: menus.menusHeader,
      menusFooter: menus.menusFooter,
      recommendSkills,
      totalNumberOpeningJobs
    },
    revalidate: AppEnv.REVALIDATE
  };
};
