import React from 'react';
import Slider from 'react-slick';
import { EventType } from 'types';

import { DLink } from '@dnse/ui';

import { getCdnPath } from '../../utils/image';
import { CustomImage } from '../CustomImage';

interface Props {
  events: EventType[];
}

const sliderSettings = {
  arrows: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 600,
  customPaging: () => (
    <div className="w-[8px] h-[8px] bg-[#f6e7e7] rounded-full mt-4" />
  )
};

export const Events: React.FC<Props> = ({ events }) => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full lg:max-w-[1280px] py-[50px]">
        <h2 className="text-[24px] lg:text-[32px] font-bold mb-8 w-full text-center">
          Sự kiện nổi bật
        </h2>

        <div className="w-full lg:hidden">
          <Slider {...sliderSettings}>
            {events.map((event, index) => (
              <div className="w-screen px-4" key={index}>
                <CustomImage
                  src={getCdnPath(event.image)}
                  key={index}
                  alt="anh-su-kien"
                  width={1280}
                  height={800}
                  // className="h-[200px] sm:h-[300px] md:h-[420px] px-4"
                  priority={true}
                  loading={'eager'}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="hidden lg:flex items-center justify-center gap-2 flex-wrap">
          {events.map((event, index) => (
            <div key={index}>
              <img
                key={index}
                src={getCdnPath(event.image)}
                alt="anh-su-kien"
                className="rounded h-[250px]"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-14 lg:mt-8">
          <DLink
            data-testid="view-more-events-button"
            href="/happy-dnse/su-kien"
            className="w-[200px] h-[37px] lg:h-[48px] flex items-center justify-center text-brand-300 font-medium text-base rounded-[41px] border-[1px] border:solid border-brand-300"
          >
            Xem tất cả
          </DLink>
        </div>
      </div>
    </div>
  );
};
