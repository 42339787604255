import throttle from 'lodash/throttle';
import { useEffect } from 'react';

export const useThrottleEventListener = (
  eventName: string,
  callback: () => void,
  throttleTime = 100,
  deps: any[] = []
) => {
  const eventHandler = throttle(callback, throttleTime);
  useEffect(() => {
    document.addEventListener(eventName, eventHandler);
    return () => document.removeEventListener(eventName, eventHandler);
  }, deps);
};
