import { JobCard } from 'components/common/Job/JobCard';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { JobType } from 'types';

import { DLink } from '@dnse/ui';


interface Props {
  openingJobList: JobType[];
}

const sliderSettings = {
  arrows: false,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 600,
  customPaging: () => (
    <div className="slick-dot bg-[#f6e7e7] w-[8px] h-[8px] rounded-full mt-2" />
  )
};

export const OpeningJobs: React.FC<Props> = ({ openingJobList }) => {
  return (
    <div
      id="OpeningJobsElement"
      className="w-full flex justify-center relative overflow-hidden "
    >
      <img
        src={'/svg/eclippse-mobile.svg'}
        alt="eclippse-svg"
        className="-z-10 absolute lg:hidden -right-[18px] top-[30px]"
      />
      <div className="-z-10 absolute bottom-0 left-0 rounded-tr-[320px] lg:rounded-tr-[400px] w-full lg:w-calc h-[524px] bg-opacity-10 bg-blue-500" />
      <div className="max-w-[1120px] relative overflow-hidden min-[1120px]:overflow-visible py-[50px]">
        <img
          src={'/svg/eclippse-web.svg'}
          alt="eclippse-svg"
          className="-z-10 hidden lg:block lg:absolute -right-[220px] top-[25px]"
        />
        <div className="w-full flex items-center justify-between mb-8 px-4 lg:px-0">
          <h2 className="text-[24px] lg:text-[32px] font-bold ">
            Vị trí <span className="text-brand-300">đang tuyển</span>
          </h2>
          <DLink
            data-testid="view-all-jobs-button"
            href={'/co-hoi-viec-lam'}
            className="flex items-center gap-2 font-medium text-base text-brand-300 underline"
          >
            Xem tất cả
            <img src={'svg/icon-arrow-right.svg'} alt="icon-arrow-right" />
          </DLink>
        </div>
        <div className="hidden lg:grid grid-cols-1 gap-y-6 lg:grid-cols-9 lg:gap-x-4 w-full">
          {openingJobList.map(job => (
            <div key={job.id} className="col-span-1 md:col-span-3 h-full">
              <JobCard job={job} />
            </div>
          ))}
        </div>
        <div className="lg:hidden w-full mt-4">
          <Slider {...sliderSettings}>
            {openingJobList.map(job => (
              <div key={job.id} className="py-4 px-4 h-full">
                <div className="w-full flex justify-center h-full">
                  <JobCard job={job} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
