import { SkillTag } from 'components/common/Tag/SkillTag';
import { useDebounceValue } from 'hooks';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { axiosInstance } from 'services/axios';
import { JobType } from 'types';

import { DLink, ImagePreset, SvgUse } from '@dnse/ui';


interface Props {
  recommendSkills: string[];
  totalNumberOpeningJobs: number;
}

export const Hero: React.FC<Props> = ({
  recommendSkills,
  totalNumberOpeningJobs
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debounceSearchInput = useDebounceValue(searchInput, 300);
  const [results, setResults] = useState<JobType[]>([]);
  const [isShowResult, setIsShowResult] = useState<boolean>(false);

  const router = useRouter();
  const handleClickSearchButton = () => {
    if (searchInput.length > 0) {
      router.push({
        pathname: '/co-hoi-viec-lam',
        query: {
          keyword: searchInput
        }
      });
    } else {
      const OpeningJobsElement = document.querySelector('#OpeningJobsElement');
      OpeningJobsElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  };

  const searchJobsWithKeyword = async (input: string) => {
    if (input.length > 0) {
      const apiUrl = 'api/jobs';
      const payload = {
        page: 1,
        limit: 5,
        address: [],
        workType: [],
        department: [],
        skill: [],
        title: input
      };
      await axiosInstance
        .post(apiUrl, payload)
        .then(data => {
          setResults(data.data.jobs);
          setIsShowResult(true);
        })
        .catch(error => {
          setResults([]);
          console.log(error);
        });
    } else {
      setResults([]);
      setIsShowResult(false);
    }
  };

  useEffect(() => {
    searchJobsWithKeyword(debounceSearchInput);
  }, [debounceSearchInput]);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleClickSearchButton();
    }
  };

  return (
    <div className="mt-[56px] mb-[60px] lg:mb-0 lg:h-[600px] flex justify-center items-center overflow-hidden 2xl:overflow-visible">
      <div className="w-full lg:max-w-[1280px] lg:pl-20 h-full flex flex-col lg:flex-row lg:items-center lg:grid lg:grid-cols-12">
        <div className="lg:col-span-6 flex flex-col items-center lg:items-start px-4 lg:px-0">
          <h1 className="text-center lg:text-left mt-6 lg:mt-0 text-[32px] sm:text-[50px] font-bold text-brand-300 leading-none">
            "Vũ trụ" cho người trẻ <br />
            <span className="text-[24px] sm:text-[40px] font-normal text-gray-300 text-center lg:text-left leading-none">
              sáng tạo tại Fintech tiên <br />
              phong về{' '}
              <span className="relative">
                chứng khoán số
                <img
                  className="absolute right-0  -bottom-1 lg:-bottom-2"
                  src={'/svg/underline-hero.svg'}
                  alt="underline"
                />
              </span>
            </span>
          </h1>

          <div className="mt-6 text-gray-300 font-medium flex flex-col items-center lg:block">
            <span className="text-brand-300">
              <span className="font-bold">{totalNumberOpeningJobs}</span> vị trí
              đang tuyển {''}
            </span>
            tại TP. Hà Nội và TP. Hồ Chí Minh
          </div>
          <div className="my-3 flex items-center gap-x-2 w-full">
            <div className="relative w-full lg:w-[400px]">
              <div className="absolute ml-3 left-0 mt-[11px]">
                <SvgUse id={'dnse-logo'} className="w-5 h-5" />
              </div>
              <input
                onChange={e => setSearchInput(e.currentTarget.value)}
                onKeyDown={e => handleOnKeyDown(e)}
                data-testid="search-input"
                placeholder="Nhập từ khoá theo kĩ năng, vị trí..."
                className="w-full text-sm text-primaryolder:text h-10 py-1.5 pl-10 pr-2 rounded-full bg-[#F6F6F6] border-transparent border focus:!border-encap focus:outline-none placeh-[#858585]"
              />
              <div className="absolute lg:hidden mr-3 right-0 top-[11px]">
                <SvgUse
                  id="search-big-outline"
                  className="w-5 h-5"
                  onClick={handleClickSearchButton}
                />
              </div>
              <div
                data-testid="search-result"
                className="absolute w-full flex flex-col rounded shadow-md mt-1 z-10"
                style={{
                  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
                  backdropFilter: 'blur(20px)',
                  background: 'var(--light-bg-60, rgba(255, 255, 255, 0.60))'
                }}
              >
                {isShowResult ? (
                  results.length > 0 ? (
                    results.map(job => (
                      <DLink
                        href={`co-hoi-viec-lam/${job.slug}`}
                        key={job.id}
                        className="p-3 text-sm font-normal cursor-pointer z-10"
                      >
                        {job.title}
                      </DLink>
                    ))
                  ) : (
                    <div className="text-sm font-normal p-3">
                      <span>
                        Không tìm thấy kết quả nào cho{' '}
                        <span className="font-medium">{searchInput}</span>
                        <br />
                      </span>
                      <span className="text-gray-800">
                        Kiểm tra lại từ khóa hoặc thử một cụm từ tìm kiếm khác.
                      </span>
                    </div>
                  )
                ) : null}
              </div>
            </div>
            <button
              onClick={handleClickSearchButton}
              data-testid="search-button"
              className="hidden lg:block whitespace-nowrap px-6 py-2 rounded-[40px] text-base font-medium text-white bg-brand-300 col-span-3"
            >
              Tìm kiếm
            </button>
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:items-center justify-start text-gray-700 text-sm font-normal gap-2 flex-wrap mb-2">
            <span className="text-xs">
              Gợi ý cho bạn:
              <br />
            </span>
            <div className="flex items-center gap-2 flex-wrap">
              {recommendSkills.map((skill, index) => (
                <SkillTag key={index} name={skill} />
              ))}
            </div>
          </div>
        </div>
        <div className="h-full mt-[30px] lg:col-span-6 lg:mt-0 ">
          <ImagePreset
            src={'/images/anh-nhom-mobile.png'}
            className="block lg:hidden"
          />
          <ImagePreset
            src={'/images/hero-banner-web.png'}
            className="hidden lg:block xl:hidden"
          />
          <ImagePreset
            src={'/images/hero-banner-web-2xl.png'}
            className="hidden xl:block scale-125 mt-14"
          />
        </div>
      </div>
    </div>
  );
};
