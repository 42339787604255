import sanitizeHtml from 'sanitize-html';

export const formatDateRange = (
  startDateString: string,
  endDateString: string
) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();

  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth() + 1;
  const endDay = endDate.getDate();

  const formattedStartDate = `${startDay
    .toString()
    .padStart(2, '0')}/${startMonth.toString().padStart(2, '0')}`;
  const formattedEndDate = `${endDay.toString().padStart(2, '0')}/${endMonth
    .toString()
    .padStart(2, '0')}`;

  return `${formattedStartDate} — ${formattedEndDate}/${endYear}`;
};

export const formatSalaryRange = (startSalary?: number, endSalary?: number, currency?: string) => {
  const symbol = currency === 'VND' ? 'M' : '$';
  const MILLION_MONEY_UNIT = 1_000_000;
  const [formatStartSalary, formatEndSalary] = [startSalary, endSalary].map((salary) => currency === 'VND' ? (salary / MILLION_MONEY_UNIT).toFixed(0) : salary);
  if (startSalary && endSalary) return `${formatStartSalary}${symbol} - ${formatEndSalary}${symbol}`;
  if (!startSalary && endSalary) return `Up to ${formatEndSalary}${symbol}`;
  return 'Thoả thuận';
};

export const findTopFiveFrequentSkills = (
  arr: { skills_id: { name: string } }[]
): string[] => {
  const nameCount: { [name: string]: number } = arr.reduce((count, obj) => {
    const { name } = obj.skills_id;
    // eslint-disable-next-line no-param-reassign
    count[name] = (count[name] || 0) + 1;
    return count;
  }, {});

  const sortedNames = Object.entries(nameCount).sort((a, b) => b[1] - a[1]);

  return sortedNames.slice(0, 5).map(([name]) => name);
};

export const replaceVietnameseCharacterWithUnderscore = (
  str: string
): string => {
  if (!str) return '';
  const diacriticsMap: { [key: string]: string } = {
    aáàảãạăắằẳẵặâấầẩẫậ: '_',
    eéèẻẽẹêếềểễệ: '_',
    oóòỏõọôốồổỗộơớờởỡợ: '_',
    uúùủũụưứừửữự: '_',
    iíìỉĩị: '_',
    yýỳỷỹỵ: '_',
    dđ: '_'
  };
  // eslint-disable-next-line guard-for-in
  for (const diacriticGroup in diacriticsMap) {
    const regex = new RegExp(`[${diacriticGroup}]`, 'g');
    // eslint-disable-next-line no-param-reassign
    str = str.replace(regex, diacriticsMap[diacriticGroup]);
  }
  return str;
};

export function cleanAllHTML(html: string) {
  const cleanHTML = sanitizeHtml(html, {
    allowedTags: false,
    allowedAttributes: false,
    allowVulnerableTags: true
  });
  const re = /<([a-z]+|\/[a-z]+|[a-z]+ +\/)>/g;
  return cleanHTML.replaceAll(re, '');
}

export const generateIdFromHeadingContent = (content: string) => {
  return content
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
};
