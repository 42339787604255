/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { ApplyContext, ApplyStep } from '../../../context/apply.context';
import { JobType } from '../../../types';
import { formatDateRange, formatSalaryRange } from '../../../utils/helper';
import { AddressTag } from '../Tag/AddressTag';
import { SkillTag } from '../Tag/SkillTag';
import { WorkTypeTag } from '../Tag/WorkTypeTag';

const SuperHotTag = () => {
  return (
    <div className="flex items-center justify-center gap-1 absolute -top-4 -left-0 bg-red-500 px-1.5 py-0.5 text-[12px] font-medium text-white rounded-full">
      <img src={'/svg/icon-flame.svg'} alt="menu" width={14} height={14} />
      Siêu Hot
    </div>
  );
};

interface JobCardProps {
  job: JobType;
  shortVersion?: boolean;
}

export const JobCard: React.FC<JobCardProps> = ({
  job,
  shortVersion = false
}) => {
  const {
    title,
    department,
    description,
    address,
    work_type,
    start_date,
    end_date,
    skills,
    start_salary,
    end_salary,
    slug,
    super_hot,
    currency
  } = job;

  const { setStep } = useContext(ApplyContext);
  const router = useRouter();

  const handleClickApplyButton = e => {
    setStep(ApplyStep.UploadCV);
    router.push(`/co-hoi-viec-lam/${slug}`);
    e.stopPropagation();
  };

  const handleClickJobCard = () => {
    setStep(ApplyStep.ViewJobDetails);
    router.push(`/co-hoi-viec-lam/${slug}`);
  };
  return (
    <div
      data-testid="job-card"
      onClick={handleClickJobCard}
      className="relative w-full flex flex-col px-3 py-4 gap-y-3 bg-gray-1000 shadow rounded h-full"
    >
      {super_hot && <SuperHotTag />}
      <h3
        className="text-lg font-semibold w-full h-[42px] leading-none cursor-pointer"
        data-testid="job-title"
      >
        {title}
      </h3>
      <div className="flex items-center gap-x-1 w-full">
        <AddressTag address={address} />
        <WorkTypeTag name={work_type} />
      </div>
      {!shortVersion && (
        <>
          <p
            className="text-brand-300 text-xs font-medium"
            data-testid="job-department"
          >
            {department}
          </p>
          <p
            className="text-xs font-normal text-gray-700 overflow-hidden line-clamp-2"
            data-testid="job-description"
          >
            {description}
          </p>
          <div className="flex items-center text-xs gap-x-2">
            <img
              src={'/svg/icon-calendar.svg'}
              alt="menu"
              width={16}
              height={16}
            />
            <span className="text-gray-700">
              Hạn nộp:
              <span
                className="font-medium text-gray-300 ml-1"
                data-testid="job-deadline"
              >
                {formatDateRange(start_date, end_date)}
              </span>
            </span>
          </div>
          <div className="flex items-center text-xs gap-x-2">
            <img
              src={'/svg/icon-money.svg'}
              alt="menu"
              width={16}
              height={16}
            />
            <span className="text-gray-700">
              Mức lương:
              <span
                className="font-medium text-green-400 ml-1"
                data-testid="job-salary"
              >
                {formatSalaryRange(start_salary, end_salary, currency)}
              </span>
            </span>
          </div>
          <div className="flex items-start flex-wrap gap-x-2 gap-y-2 flex-1">
            {skills?.map((skill, index) => (
              <SkillTag
                name={skill.skills_id.name}
                key={index}
                isInJobCard={true}
              />
            ))}
          </div>
          <button
            className="text-brand-300 font-medium text-base px-6 py-2 rounded-[41px] border-[1px] border:solid border-brand-300"
            onClick={e => handleClickApplyButton(e)}
            data-testid="apply-button"
          >
            Ứng tuyển
          </button>
        </>
      )}
    </div>
  );
};
