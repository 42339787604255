import { useRouter } from 'next/router';
import React from 'react';

import { SvgUse } from '@dnse/ui';

interface Props {
  address: string[];
  onClick?: () => void;
}

export const AddressTag: React.FC<Props> = ({ address, onClick }) => {
  const router = useRouter();
  const currentQueryArray = router.query.address
    ? decodeURIComponent(router.query.address.toString()).split(',')
    : [];
  const handleOnClickTag = e => {
    address.forEach(item => {
      const index = currentQueryArray.indexOf(item);
      if (index === -1) {
        currentQueryArray.push(item);
      } else {
        currentQueryArray.filter(query => query !== item);
      }
    });
    router.push({
      pathname: '/co-hoi-viec-lam',
      query: {
        ...router.query,
        address: encodeURIComponent(currentQueryArray.join(','))
      }
    });
    e.stopPropagation();
  };

  return (
    <button
      data-testid="address-tag-element"
      onClick={e => handleOnClickTag(e)}
      className="flex items-center justify-center gap-1 rounded-full px-3 py-1 text-xs font-normal text-blue-500 bg-opacity-10 bg-blue-400"
    >
      <SvgUse id="location-outline" width={16} height={16} />
      {address.length > 1 ? `${address[0]} - ${address[1]}` : address[0]}
    </button>
  );
};
