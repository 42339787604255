interface BaseModel {
  id?: string;
  status?: string;
  date_created?: string;
  date_updated?: string;
}

export interface Author extends BaseModel {
  first_name: string;
  last_name: string;
  avatar: DirectusFile;
}

export interface Hashtag {
  name: string;
}

export interface EventType extends BaseModel {
  __typename?: string;
  title?: string;
  content?: string;
  cover_image?: DirectusFile;
  image?: string;
  slug?: string;
  user_created?: Author;
  hashtag?: { id: string; hashtag_id: Hashtag }[];
  sapo?: string;
}

export interface NewsType extends BaseModel {
  __typename: string;
  title: string;
  content: string;
  cover_image: DirectusFile;
  time: string;
  slug: string;
  user_created: Author;
  hashtag: { id: string; hashtag_id: Hashtag }[];
  sapo?: string;
}

export interface Skill {
  name: string;
}

export interface JobType extends BaseModel {
  title?: string;
  description?: string;
  address?: Address[];
  work_type?: WorkType;
  department?: string;
  end_date?: string;
  start_date?: string;
  start_salary?: number;
  end_salary?: number;
  currency?: string;
  skills?: { id: string; skills_id: Skill }[];
  slug?: string;
  job_description: string;
  job_requirement: string;
  job_benefit: string;
  super_hot?:boolean;
}

export enum WorkType {
  FULLTIME = 'Toàn thời gian',
  COLLBORATOR = 'Cộng tác viên',
  INTERN = 'Thực tập'
}

export enum Address {
  'Hà Nội' = 'Hà Nội',
  'Hồ Chí Minh' = 'Hồ Chí Minh'
}

export enum Department {
  'Customer Love' = 'Customer Love',
  'Techies' = 'Techies',
  'Kiểm soát nội bộ' = 'Kiểm soát nội bộ',
  'Hành chính' = 'Hành chính',
  'Nhân sự' = 'Nhân sự',
  'Đối tác & Phát triển sản phẩm' = 'Đối tác & Phát triển sản phẩm'
}

export interface DirectusFile {
  id: string;
  width: number;
  height: number;
}

export interface BlogType extends NewsType {}
