import { useRouter } from 'next/router';
import React from 'react';

interface Props {
  name: string;
}

export const SkillTag: React.FC<Props> = ({ name }) => {
  const router = useRouter();
  const currentQueryArray = router.query.skill
    ? decodeURIComponent(router.query.skill.toString()).split(',')
    : [];
  const handleClickTag = e => {
    const index = currentQueryArray.indexOf(name);
    if (index === -1) {
      currentQueryArray.push(name);
    } else {
      currentQueryArray.splice(index, 1);
    }
    if (currentQueryArray.length > 0) {
      router.push({
        pathname: '/co-hoi-viec-lam',
        query: {
          ...router.query,
          skill: encodeURIComponent(currentQueryArray.join(','))
        }
      });
    } else {
      const { skill, ...rest } = router.query;
      router.push({
        pathname: '/co-hoi-viec-lam',
        query: rest
      });
    }
    e.stopPropagation();
  };
  const isSelected = currentQueryArray.includes(name);

  return (
    <button
      className={`flex items-center justify-center rounded-full px-3 py-[5px] border border-solid  text-xs  hover:border-brand-300 hover:text-brand-300 hover:bg-brand-300/[.15] ${
        isSelected
          ? 'border-brand-300 text-brand-300 bg-brand-300/[.15]'
          : ' border-gray-800 text-gray-700'
      }`}
      data-testid="skill-tag-element"
      onClick={e => handleClickTag(e)}
    >
      {name}
    </button>
  );
};
