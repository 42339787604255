import { NewsCard } from 'components/News/NewsCard';
import React from 'react';
import { NewsType } from 'types';

import { DLink } from '@dnse/ui';

interface Props {
  newsList: NewsType[];
}

export const News: React.FC<Props> = ({ newsList }) => {
  return (
    <div
      className="w-full lg:flex justify-center"
      style={{
        background: 'linear-gradient(178deg, #FFE8F3 1%, #FEF6F9 51.44%)'
      }}
    >
      <div className="max-w-[1120px] px-4 py-[50px] lg:px-0">
        <div className="w-full flex items-center justify-center mb-8">
          <h2 className="text-[24px] lg:text-[32px] font-bold">
            Tin tức về DNSE
          </h2>
        </div>
        <div className="grid grid-cols-1 gap-y-6 lg:grid-cols-9 lg:gap-x-6">
          {newsList.map(news => (
            <div key={news.id} className="col-span-1 lg:col-span-3">
              <NewsCard news={news} />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <DLink
            data-testid="view-more-news-button"
            href="/happy-dnse/tin-tuc"
            className="w-[200px] h-[37px] lg:h-[48px] flex items-center justify-center text-brand-300 font-medium text-base rounded-[41px] border-[1px] border:solid border-brand-300"
          >
            Xem tất cả
          </DLink>
        </div>
      </div>
    </div>
  );
};
