import { AppEnv } from './accessEnviroment';

export const CDN_DNSE = 'https:/cdn.dnse.com.vn';
export const CDN_ENTRADE = 'https:/cdn.entrade.com.vn';
export const CDN_DNSE_FIX = 'https://cdn.dnse.com.vn';
export const CDN_ENTRADE_FIX = 'https://cdn.entrade.com.vn';

export function getCdnPath(path: string) {
  if (path.startsWith(CDN_DNSE)) {
    return path.replace(CDN_DNSE, CDN_DNSE_FIX);
  }
  if (path.startsWith(CDN_ENTRADE)) {
    return path.replace(CDN_ENTRADE, CDN_ENTRADE_FIX);
  }
  if (AppEnv.NODE_ENV === 'production') {
    return `${AppEnv.CDN_PREFIX}/${path}`;
  }
  return `${path}`;
}
