import { DateUtils } from '@broker/utils';
import { CustomImage } from 'components/CustomImage';
import { BasePath } from 'constants/basePath';
import React from 'react';
import { getDirectusImg } from 'services/graphql/directus';
import { BlogType, EventType, NewsType } from 'types';

import { DLink, SvgUse } from '@dnse/ui';

interface Props {
  news: NewsType | BlogType | EventType;
  isHottestNews?: boolean;
  imageWidth?: string;
  imageHeight?: string;
  basePath?: string;
}
export const NewsCard: React.FC<Props> = ({
  news,
  isHottestNews = false,
  imageHeight = '200',
  imageWidth = '360',
  basePath = BasePath.NewsBasePath
}) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, cover_image, slug } = news;
  return (
    <DLink href={`${basePath}/${slug}`} data-testid="news-card">
      <div className={'flex flex-col gap-y-0.5'}>
        <CustomImage
          src={getDirectusImg(cover_image)}
          alt={title}
          height={imageHeight}
          width={imageWidth}
          className="rounded"
        />
        <h4
          className={`text-lg font-semibold mt-2 line-clamp-3 ${
            isHottestNews ? 'hidden' : ''
          }`}
        >
          {title}
        </h4>
      </div>
    </DLink>
  );
};
