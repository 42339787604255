import React from 'react';

import { ImagePreset, SvgUse } from '@dnse/ui';

import { getCdnPath } from '../../utils/image';


interface Props {
  benefits?: string[];
}

export const Benefit: React.FC<Props> = ({
  benefits = [
    'Thuộc hàng ngũ top 1% trong lĩnh vực. Cởi mở, năng động, hỗ trợ đồng đội cùng phát triển',
    'Văn hoá startup tập trung vào sản phẩm và khách hàng, song song với phát triển năng lực team và cá nhân',
    'Được chủ động, sáng tạo thiết kế và phát triển các sản phẩm hấp dẫn người dùng',
    'Lương thưởng và đãi ngộ thuộc hàng TOP của thị trường'
  ]
}) => {
  return (
    <div className="w-full md:flex justify-center mb-10 overflow-hidden">
      <div className="w-full max-w-[1120px] md:h-[600px]">
        <div className="w-full md:items-center flex flex-col-reverse md:flex-row md:grid md:grid-cols-12">
          <div className="flex md:col-span-6 relative h-full pl-0 lg:pl-4">
            <ImagePreset
              src={getCdnPath('/images/anh-bo-va-gau.png')}
              alt="anh-bo-va-gau"
              className="md:absolute bottom-0 -left-20"
            />
          </div>
          <div className="md:col-span-6 h-full relative overflow-hidden min-[1120px]:overflow-visible">
            <div className="h-full">
              <div className="w-fit mb-[30px] md:mt-[106px] mx-auto md:ml-[100px] font-normal md:text-[28px] text-center md:text-left">
                <div className=" mb-[36px]">
                  <h2 className="text-[24px] md:text-[32px] text-gray-300">
                    <span className="font-bold">Lợi ích</span> <br />
                    <span className="relative text-[18px] md:text-[24px] w-fit">
                      khi làm việc tại DNSE
                      <img
                        className="absolute right-0"
                        src={'/svg/underline.svg'}
                        alt="underline"
                        width={110}
                        height={0}
                      />
                    </span>
                  </h2>
                </div>
                <div className="text-[16px] font-[400px] flex flex-col gap-y-[16px] max-w-[343px]">
                  {benefits.map((benefit, index) => (
                    <div key={index} className="flex items-start gap-2">
                      <span className="text-[20px] md:text-[30px] font-extrabold text-brand-300">
                        {(index + 1).toString().padStart(2, '0')}.
                      </span>
                      <span className="text-left text-[16px]">{benefit}</span>
                    </div>
                  ))}
                </div>

                <div className="bg-brand-300 bg-opacity-5 rounded-tl-[180px] md:rounded-tl-[280px] w-full h-[75%] md:h-full md:w-screen absolute bottom-0 left-0 ">
                  <div className="absolute left-[180px] md:left-[380px] top-[25px] opacity-5">
                    <SvgUse id={'dnse-logo'} className="w-96 h-96" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
