import Image, { ImageProps } from 'next/image';
import { useState } from 'react';
import { getCdnPath } from 'utils/image';

import { getBase64Img, myDirectusLoader } from '../utils/shimmer';

interface Props extends Omit<ImageProps, 'src'> {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
  defaultSrc?: string;
}

export const CustomImage: React.FC<Props> = ({
  src,
  alt,
  width = 360,
  height = 200,
  defaultSrc = 'images/banner-logo-dnse.jpg',
  ...props
}) => {
  const [isLoadedError, setIsLoadedError] = useState(false);

  return (
    <Image
      priority={false}
      className="rounded"
      src={src}
      alt={alt}
      layout={'responsive'}
      width={width}
      height={height}
      loader={myDirectusLoader}
      {...props}
      placeholder="blur"
      blurDataURL={getBase64Img(
        800,
        600,
        { from: '#CECECE', via: '#C7C7C7', to: '#D0D0D0' },
        '#C7C7C7'
      )}
      onError={event => {
        if (!isLoadedError) {
          event.target.srcset = getCdnPath(defaultSrc);
          setIsLoadedError(true);
        }
      }}
    />
  );
};
