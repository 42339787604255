import { useRouter } from 'next/router';
import React from 'react';

import { SvgUse } from '@dnse/ui';

import { WorkType } from '../../../types';


interface Props {
  name: string;
}

export const WorkTypeTag: React.FC<Props> = ({ name }) => {
  const router = useRouter();
  const currentQueryArray = router.query.workType
    ? decodeURIComponent(router.query.workType.toString()).split(',')
    : [];

  const handleClickTag = e => {
    const index = currentQueryArray.indexOf(name);
    if (index === -1) {
      currentQueryArray.push(name);
    } else {
      currentQueryArray.filter(query => query !== name);
    }
    router.push({
      pathname: '/co-hoi-viec-lam',
      query: {
        ...router.query,
        workType: encodeURIComponent(currentQueryArray.join(','))
      }
    });
    e.stopPropagation();
  };

  return (
    <button
      data-testid="worktype-tag-element"
      onClick={e => handleClickTag(e)}
      className={`flex items-center justify-center gap-1 rounded-full px-3 py-1 text-xs font-normal  bg-opacity-10 ${
        name === WorkType.FULLTIME
          ? 'text-green-500 bg-green-400'
          : 'text-[#F0A514] bg-[#F0A514]/20'
      }`}
    >
      <SvgUse
        id={
          name === WorkType.FULLTIME
            ? 'clock-outline-green'
            : 'clock-outline-orange'
        }
        width={16}
        height={16}
      />
      {name}
    </button>
  );
};
