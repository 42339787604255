const Events = [
  { image: 'images/anh-su-kien-1.png', link: '/happy-dnse/su-kien' },
  { image: 'images/anh-su-kien-2.png', link: '/happy-dnse/su-kien' },
  { image: 'images/anh-su-kien-3.png', link: '/happy-dnse/su-kien' },
  { image: 'images/anh-su-kien-4.png', link: '/happy-dnse/su-kien' },
  { image: 'images/anh-su-kien-5.png', link: '/happy-dnse/su-kien' },
  { image: 'images/anh-su-kien-6.png', link: '/happy-dnse/su-kien' }
];

export const WorkType = ['Toàn thời gian', 'Cộng tác viên', 'Thực tập'];
export const Address = ['Hà Nội', 'Hồ Chí Minh'];
export const Department = [
  'Customer Love',
  'Techies',
  'Kiểm soát nội bộ',
  'Hành chính',
  'Nhân sự'
];
export const CONST = { Events };
