const shimmer = (
  w: number,
  h: number,
  linear: { from: string; via: string; to: string },
  bgColor: string
) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="${linear.from}" offset="20%" />
      <stop stop-color="${linear.via}" offset="50%" />
      <stop stop-color="${linear.to}" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="${bgColor}" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export function getBase64Img(
  w: number,
  h: number,
  linear: { from: string; via: string; to: string },
  bgColor: string
) {
  return `data:image/svg+xml;base64,${toBase64(
    shimmer(w, h, linear, bgColor)
  )}`;
}

interface NextImageLoader {
  src: string;
  width: number;
  height?: number;
  quality?: number;
}

export const myDirectusLoader = ({ src, width, quality }: NextImageLoader) => {
  return `${src}?w=${width}&q=${quality}`;
};
